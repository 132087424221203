




















import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex';
import CapTable from '../components/CapTable.vue'
import CapFilters from '../components/CapFilters.vue'
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import { Watch } from 'vue-property-decorator';
import SygniLoader from '@/components/layout/SygniLoader.vue';

@Component({
  components: { CapTable, CapFilters, SygniSelect, SygniLoader },
  computed: mapState('capital', {
    capitalTable: (state: any) => state.capitalTable,
    isBusy: (state: any) => state.capitalTableIsBusy,
    perPage: (state: any) => state.capitalTable.perPage,
  })
})
export default class capitalModule extends Vue {
  filtersQueryString: string = '';
  capitalTable!: any;
  isBusy!: boolean;
  perPage!: number;
  isLoading: boolean = false;

  get tableData() {
    return this.capitalTable;
  }

  get accessModules() {
    return this.$store.getters['genprox/getAccessModules'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  get showLePicker() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company';
    } else {
      return this.activeUserData.context.context !== 'fund';
    }
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.capTable as CapTable).onFiltersChange(queryString);
  }

  async changeViewedCompany(id: string) {
    this.isLoading = true;
    await this.$store.dispatch('auth/changeViewedCompany', id);
    this.isLoading = false;
  }

  async changeViewedFund(id: string) {
    this.isLoading = true;
    await this.$store.dispatch('auth/changeViewedFund', id);
    this.isLoading = false;
  }

  async beforeMount() {
    this.isLoading = true;
    if(!this.isPortfolioCompany) {
      if (this.activeUserData.context.context != 'fund') {
        await this.$store.dispatch('auth/getViewedFund');
        await this.$store.dispatch('auth/getInvestedFunds');
      }
    } else {
      if(this.activeUserData.context.context != 'company') {
        await this.$store.dispatch('auth/getViewedCompany');
        await this.$store.dispatch('auth/getInvestedCompanies');
      }
    }
    this.isLoading = false;
  }

  @Watch('viewedId') onViewedFundIdChange() {
    (this.$refs.capTable as CapTable).onFiltersChange('');
  }
}
