


















































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BTable, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import { required } from 'vuelidate/lib/validators'
import CapInnerTable from './CapInnerTable.vue';
import breakpoints from '@/plugins/breakpoints';
import { Prop, Watch } from 'vue-property-decorator';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, BTable, CapInnerTable },
})
export default class CapTable extends SygniTable<any> {
  breakpoints = breakpoints;
  filtersQuery: string = 'by-nominal';
  refresh: any = null;
  declare items: any;
  
  @Prop() tableItems: any;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', sortable: false, label: 'Shareholders'},
    { key: 'founders.numberOfShares', sortable: false, label: '# OF SHARES'},
    { key: 'founders.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'investors.numberOfShares', sortable: false, borderless: false , label: '# OF SHARES' },
    { key: 'investors.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'options.numberOfShares', class: 'hide', sortable: false, label: '# OF SHARES' },
    { key: 'options.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'totals.numberOfShares', class: 'hide', sortable: false, label: '# OF SHARES' },
    { key: 'totals.value', sortable: false, label: 'VALUE' },
    { key: 'totalPercentage', sortable: false, label: 'PERCENT STRUCTURE' },
  ];

  get shareholderTotals() {
    return this.$store.getters['capital/getShareholderTotals'];
  }

  get optionsStats() {
    return this.$store.getters['capital/getOptionsStats'];
  }

  get totals() {
    return this.$store.getters['capital/getTotals'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company');
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get tableOptions() {
    if(this.tableItems?.optionsStats?.items) {
      return Object.values(this.tableItems?.optionsStats?.items);
    }

    return [];
  }

  @Watch('tableItems', { deep: true, immediate: true }) onTableDataChange() {
    this.items = Object.values(this.tableItems?.shareHoldersSeriesStats);
  }

  async getItems() {
    this.$store.commit(this.setTableBusyMutation, true);
    
    if(!this.isPortfolioCompany) {
      if (!this.viewedFundId && this.activeUserData.context.context != 'fund') {
        await this.$store.dispatch('auth/getViewedFund');
      }
    } else {
      if(!this.viewedCompanyId && this.activeUserData.context.context != 'company') {
        await this.$store.dispatch('auth/getViewedCompany');
      }
    }

    try {
      await this.$store.dispatch('capital/getItems', {
        filtersQuery: this.filtersQuery,
        viewedContext: this.viewedId
      });
      this.$store.commit(this.setTableBusyMutation, false);
    } catch (e) {
      this.$store.commit(this.setTableBusyMutation, false);
      this.$notify({
        type: 'error',
        title: 'Portfolio cannot be loaded.'
      });
    }
  }

  getTooltipMessage(message: string) {
    return message?.length <= 42 ? '' : message
  }

  openDetails(rowData: any) {
    rowData.item.details = Object.values(rowData.item.items);
    // this.$store.commit(this.setTableBusyMutation, true);
    // rowData.item.details = await this.$store.dispatch('portfolio/getPortfolioTransactionsByCompanyIdWithContext', {
    //   id: rowData.item.id,
    //   viewedContext: this.viewedFundId
    // });

    this.$nextTick(() => {
      rowData.toggleDetails();
      this.setWidths();
      // this.$store.commit(this.setTableBusyMutation, false);
    })
  }

  setWidths() {
    const columns: Array<number> = [];
    (this.$refs.capTable as CapTable).$el.querySelectorAll('tbody tr:nth-of-type(2) td .cell').forEach((column: any) => {
      columns.push(column.offsetWidth);
    });

    this.$nextTick(() => {
      const rows = [
        (this.$refs.capTable as CapTable).$el.querySelectorAll('.b-table-details tbody tr'),
        (this.$refs.capTable2 as CapTable).$el.querySelectorAll('.b-table-details tbody tr')
      ];


      rows.forEach((dataRows: any) => {
        dataRows.forEach((dataRow: any) => {
          columns.forEach((width: any, index: number) => {
            const element: HTMLElement = dataRow.querySelector(`td:nth-of-type(${index + 1})`);
            element.style.width = `${width}px`;
          })
        })
      })
    })
  }

  getTotalPercentage(amount: number) {
    if (amount === undefined) amount = 0;
    const total = this.totals.totals.value ? this.totals.totals.value : 0;
    amount = Number(amount.toFixed(2)) * 100 / Number(total.toFixed(2));
    amount = Math.round((amount + Number.EPSILON) * 100) / 100;

    return `${this.$options.filters.numberFormat(amount, 2)}%`;
  }

  @Watch('breakpoints.w') onBreakpointsChange(): void {
    setTimeout(() => {
      this.setWidths();
    }, 10)
  }

  async onFiltersChange(queryString: string) {
    this.filtersQuery = queryString;
    await this.getItems();
  }

  validations () {
    return  {
      selectedBankAccount: { required },
    }
  }

  async beforeMount() {
    await this.getItems();
    // this.onMounted();
    // this.setBorderlessTds();
  }
}

