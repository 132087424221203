

































import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing'
import { Prop, Watch } from "vue-property-decorator";
import moment from 'moment';
import { SelectOption } from "@/store/types";

interface FiltersObject {
  type: string,
  sortBy: string,
  date: string
}

@Component({
  components: { SygniCard, SygniSelect, SygniRadio, SygniDatePicker },
})

export default class CapFilters extends Vue {
  @Prop() companies: any;
  @Prop({default: false}) selectAvailable: boolean;
  @Prop({default: true}) showSelect: boolean;

  date: string = '';

  filters: FiltersObject = {
    type: 'ALL',
    sortBy: 'nominal-value',
    date: ''
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get showLePicker() {
    return this.options.length > 1;
  }

  get fundOptions(): Array<SelectOption<string>> {
    const funds = this.$store.getters['auth/getInvestedFunds'];

    if (!funds) return [];

    return funds.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    });
  }

  get companyOptions(): Array<SelectOption<string>> {
    const companies = this.$store.getters['auth/getInvestedCompanies'];

    if(!companies) return [];

    return companies.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    })
  }

  get options(): Array<SelectOption<string>> {
    return this.isPortfolioCompany ? this.companyOptions : this.fundOptions;
  }

  setViewedId(id: string): void {
    if (id) {
      this.checkCapitalAvailability(id);
    }
  }

  async checkCapitalAvailability(id: string) {
    const leAccessModules = await this.$store.dispatch('genprox/getLegalEntityAccessModules', id);
    if (!leAccessModules?.capital) {
      this.$router.push({
        name: 'capital-access-denied'
      })
    } else {
      localStorage.setItem(REDIRECTED_PATH, window.location.pathname)
      if(this.isPortfolioCompany) {
        this.$emit('changeViewedCompany', id);
      } else {
        this.$emit('changeViewedFund', id);
      }
    }
  }

  setDate(date: string) {
    if(!date) {
      this.filters.date = '';
      return;
    }

    const dateTime = new Date(date);
    const finalDate = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0)

    this.filters.date = moment(finalDate).format("YYYY-MM-DD");
  }

  buildFiltersQuery(): string {
    let query = this.filters.sortBy == 'nominal-value' ? 'by-nominal?' : 'by-issue?';

    if(this.filters.date) {
      query += `f[date]=${this.filters.date}&`;
    }

    if(this.filters.type && this.filters.type !== 'ALL') {
      query += `f[type]=${this.filters.type}`;
    }

    return query;
  }

  @Watch('filters', {deep: true}) onFiltersChange(): void {
    const filtersQuery = this.buildFiltersQuery();
    this.$emit('filtersChange', filtersQuery);
  }
}
